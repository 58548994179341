import React from 'react';
import { Card, Skeleton } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import Quote from '@mui/icons-material/FormatQuoteTwoTone';
import { useCollection } from '../../hooks/useCollection'
import { Link } from 'react-router-dom';

const RelatedQuotes = ({ quote, author }) => {
    const { documents: quotes, isPending } = useCollection('quotes', [
        ['id', '!=', quote.id],
        ['author.id', '==', author.id]
    ],)

    return (
        <Card sx={{ mb: 2 }}>
            <CardContent>
                <Typography variant="h5" fontWeight={700}>
                    {`More quotes by ${author.firstName} ${author.lastName}`}
                </Typography>
                {quotes && quotes.length === 0 && (
                    <Typography sx={{ mt: 1.5 }}>{`${author.firstName} ${author.lastName} does not have any more quotes.`}</Typography>
                )}
                {quotes && quotes.map(quote => (
                    <Link to={`/${author.id}/quotes/${quote.id}`} key={quote.id} style={{ textDecorationColor: '#e91e63' }}>
                        <Typography color="primary" sx={{ my: 2 }}>{`"${quote.text}"`}</Typography>
                    </Link>
                ))}
                {isPending && (
                    <>
                        <Skeleton height='20px' />
                        <Skeleton height='20px' width="80%" />
                        <Skeleton height='20px' width="60%" />
                    </>
                )}
            </CardContent>
        </Card >
    );
}

export default RelatedQuotes;
