import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchBar from './SearchBar';
import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../hooks/useLogout';
import { Divider } from '@mui/material';
import PersonIcon from '@mui/icons-material/AccountCircle';

const settings = ['Logout'];

const AppLayout = ({ children, setQuery }) => {
    const { user } = useAuthContext()
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const { logout, error, isPending } = useLogout()

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuClick = (setting) => {
        switch (setting) {
            case 'Profile':
                console.log('Profile')
                break;
            case 'Account':
                console.log('Account')
                break;
            case 'Dashboard':
                console.log('Dashboard')
                break;
            case 'Logout':
                logout();
                console.log('Logout')
                break;
            default:
                console.log('default')
        }
        handleCloseUserMenu()
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            {user && (
                <AppBar position="sticky">
                    <Toolbar>
                        <Typography
                            variant="h6"
                            fontWeight={600}
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
                        >
                            DWB Quotable Quips
                        </Typography>
                        <SearchBar setQuery={setQuery} />
                        <Box sx={{ flexGrow: 0, pl: 1.5 }}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                    {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
                                    <PersonIcon sx={{ color: 'white', fontSize: '35px' }} />
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={() => handleMenuClick(setting)}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>
            )}
            {children}
        </Box>
    );
}

export default AppLayout;
