import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { pink, purple } from '@mui/material/colors';
import AppLayout from './components/AppLayout';
import Quotes from './pages/quotes/Quotes';
import CssBaseline from '@mui/material/CssBaseline';
import Login from './pages/login/Login';
import { useAuthContext } from './hooks/useAuthContext';
import Search from './pages/search/Search';
import { useState } from 'react';
import QuoteDetails from './pages/quoteDetails/QuoteDetails';
import SignUp from './pages/signup/SignUp';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: pink,
    secondary: purple
  },
})

function App() {
  const { authIsReady, user } = useAuthContext()
  const [query, setQuery] = useState('')

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {authIsReady && (
        <BrowserRouter>
          <AppLayout setQuery={setQuery} query={query}>
            <Routes>
              <Route exact path="/" element={user ? <Quotes query={query} /> : <Navigate to="/login" />} />
              <Route path="/login" element={!user ? <Login /> : <Navigate to="/" />} />
              <Route path="/signup" element={!user ? <SignUp /> : <Navigate to="/" />} />
              <Route path="/search" element={user ? <Search /> : <Navigate to="/login" />} />
              <Route path="/:authorID/quotes/:id" element={user ? <QuoteDetails /> : <Navigate to="/login" />} />
            </Routes>
          </AppLayout>
        </BrowserRouter>
      )}
    </ThemeProvider>
  );
}

export default App;
