import React from 'react';
import { useDocument } from '../../hooks/useDocument'
import { useParams } from 'react-router-dom'
import { Grid } from '@mui/material';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import Quote from '@mui/icons-material/FormatQuoteTwoTone';
import QuoteCard from '../../components/QuoteCard';
import RelatedQuotes from './RelatedQuotes';
import { useCollection } from '../../hooks/useCollection'
import { Link } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import HomeIcon from '@mui/icons-material/HomeTwoTone';
import PersonIcon from '@mui/icons-material/PersonTwoTone';

const QuoteDetails = () => {
    const { id } = useParams()
    const { document: quote } = useDocument('quotes', id)
    return (
        <>
            {quote && (
                <>
                    <Grid container sx={{ justifyContent: 'center' }} p={2} spacing={2}>
                        <Grid item xs={12} sm={10} md={12} lg={10} xl={8}>
                            <Breadcrumbs aria-label="breadcrumb" >
                                <Link to="/" href="/" style={{ textDecorationColor: '#e91e63', alignItems: 'center', display: 'inline-flex' }}>
                                    <HomeIcon color="primary" sx={{ mr: 0.75 }} fontSize="inherit" />
                                    <Typography color="primary" sx={{ display: 'inline' }}>All Quotes</Typography>
                                </Link>
                                <Link href="/" style={{ textDecorationColor: '#e91e63', alignItems: 'center', display: 'inline-flex' }}>
                                    <PersonIcon color="primary" sx={{ mr: 0.75 }} fontSize="inherit" />
                                    <Typography color="primary" sx={{ display: 'inline' }}>{`${quote.author.firstName} ${quote.author.lastName}`}</Typography>
                                </Link>
                                <>
                                    <Quote sx={{ mr: 0.75, alignItems: 'center', display: 'inline-flex' }} fontSize="inherit" />
                                    <Typography sx={{ display: 'inline' }}>{`${quote.text.substring(0, 18).trim()}...`}</Typography>
                                </>

                            </Breadcrumbs>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ justifyContent: 'center' }} px={2} spacing={2}>
                        <Grid item xs={12} sm={10} md={7} lg={6} xl={5}>
                            <QuoteCard quote={quote} />
                        </Grid>
                        <Grid item xs={12} sm={10} md={5} lg={4} xl={3}>
                            <RelatedQuotes author={quote.author} quote={quote} />
                        </Grid>
                    </Grid>
                </>
            )
            }
        </>
    );
}

export default QuoteDetails;
