import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyCE15yNwTrMudxoMz2D1Z0k_TV5Ve3P1GU",
    authDomain: "dwb-quote-book.firebaseapp.com",
    projectId: "dwb-quote-book",
    storageBucket: "dwb-quote-book.appspot.com",
    messagingSenderId: "444822931612",
    appId: "1:444822931612:web:8b753afbf61738bc69c559"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize timestamp
const timestamp = firebase.firestore.Timestamp;

// Initialize services
const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

export { projectStorage, projectFirestore, projectAuth, timestamp };