import { Container } from '@mui/material';
import React from 'react';
import { useCollection } from '../../hooks/useCollection'
import QuoteList from '../../components/QuoteList';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import EditIcon from '@mui/icons-material/Edit';
import Skeleton from '@mui/material/Skeleton';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Quote from '@mui/icons-material/FormatQuote';
import AddPerson from '@mui/icons-material/PersonAdd';
import { useState, useEffect } from 'react';
import AddQuoteModal from '../../components/AddQuoteModal';
import Masonry from '@mui/lab/Masonry';
import Card from '@mui/material/Card';

const Quotes = ({ query }) => {
    const { documents, isPending, error } = useCollection('quotes')
    const [modalOpen, setModalOpen] = useState(false);

    const [filteredQuotes, setFilteredQuotes] = useState([])

    useEffect(() => {
        if (documents) {
            setFilteredQuotes(documents.filter(doc => {
                if (!query || query.length === 0) return true
                return doc.text.toLowerCase().includes(query.toLowerCase())
            }))
        }
    }, [query, documents]);

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            {isPending && (
                <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={1.5}>
                    {[...Array(25).keys()].map(i => (
                        <Card sx={{ width: 300, m: 2, p: 3 }} key={i}>
                            <Skeleton animation="wave" variant="circular" width={40} height={40} />
                            <Skeleton sx={{ mt: 2, mb: 0.75 }} height='35px' />
                            <Skeleton animation="wave" width="60%" />
                        </Card>

                    ))}
                </Masonry>
            )}
            {!isPending && <QuoteList quotes={filteredQuotes} />}
            <SpeedDial
                ariaLabel="SpeedDial tooltip example"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                icon={<SpeedDialIcon />}
                onClick={() => setModalOpen(true)}
            />
            <AddQuoteModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </Container >
    );
}

export default Quotes;
