import { Container, Grid } from '@mui/material';
import React from 'react';
import { useCollection } from '../../hooks/useCollection'
import QuoteList from '../../components/QuoteList';
import { useLocation } from 'react-router-dom';

const Search = () => {
    const queryString = useLocation().search
    const queryParams = new URLSearchParams(queryString)
    const query = queryParams.get('q')
    console.log(query)
    const { documents, loading, error } = useCollection('quotes', ["text", "array", query])

    return (
        <Container maxWidth="xl" sx={{ p: 2 }} >
            <QuoteList quotes={documents} />
        </Container>
    );
}

export default Search;
