import React from 'react';
import { Card } from '@mui/material';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import Quote from '@mui/icons-material/FormatQuoteTwoTone';
import { Link } from 'react-router-dom';

const QuoteCard = ({ quote }) => {
    return (
        <Card>
            <CardContent>
                <Quote sx={{ fontSize: 50 }} />
                <Typography variant="h5" component="div" fontWeight={700}>
                    {quote.text}
                </Typography>
                <Link href="/" style={{ textDecorationColor: '#e91e63', alignItems: 'center', display: 'inline-flex' }}>
                    <Typography color="primary" sx={{ display: 'inline', my: 1 }}>{`${quote.author.firstName} ${quote.author.lastName}`}</Typography>
                </Link>

            </CardContent>
        </Card>

    );
}

export default QuoteCard;
