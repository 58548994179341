import { Card } from '@mui/material';
import React from 'react';
import { CardContent } from '@mui/material';
import { Typography } from '@mui/material';
import Quote from '@mui/icons-material/FormatQuoteTwoTone';
import QuoteCard from './QuoteCard';
import { Grid } from '@mui/material';
import { Masonry } from '@mui/lab';
import { Link } from 'react-router-dom';

const QuoteList = ({ quotes }) => {
    return (
        <>
            {quotes.length === 0 && (
                <Typography variant="h5" align="center" color="grey" fontWeight={600} sx={{ mt: 2 }}>
                    No quotes found.
                </Typography>
            )}
            <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={1.5}>
                {quotes && quotes.map(quote => (
                    <Link to={`/${quote.author.id}/quotes/${quote.id}`} style={{ textDecoration: 'none' }} key={quote.id}>
                        <QuoteCard quote={quote} />
                    </Link>

                ))}
            </Masonry>
        </>
    );
}

export default QuoteList;
