import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { useFirestore } from '../hooks/useFirestore';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useCollection } from '../hooks/useCollection';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { v4 as uuid } from 'uuid';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';

const modalStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    margin: 'auto',
    alignItems: 'center',
    width: '100%',
    maxWidth: { xs: '100%', sm: '500px', md: '500px', lg: '500px', xl: '500px' },
    bgcolor: '#fff',
    border: '1px solid #000',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
};

const filter = createFilterOptions();

const AddQuoteModal = ({ modalOpen, setModalOpen }) => {
    const { addDocument } = useFirestore('authors');
    const { addDocument: addQuote, error: errorAddingQuote, isPending: addingQuote } = useFirestore('quotes');
    const { documents, error, isPending } = useCollection('authors', null, ['firstName', 'asc']);
    const [quote, setQuote] = useState('');
    const [author, setAuthor] = useState('');

    const [newAuthorValue, setNewAuthorValue] = useState({
        id: '',
        firstName: '',
        lastName: '',
    });

    const [addAuthorDialogOpen, setAddAuthorDialogOpen] = useState(false);
    const [authorAutocompleteOpen, setAuthorAutocompleteOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = authorAutocompleteOpen && options.length === 0;

    const handleNewAuthorModalClose = () => {
        setNewAuthorValue({
            id: '',
            firstName: '',
            lastName: '',
        });
        setAddAuthorDialogOpen(false);
    };

    const handleNewAuthorSubmit = async (event) => {
        event.preventDefault();
        const newAuthor = {
            ...newAuthorValue,
            id: uuid(),
        }
        addDocument(newAuthor)
        setAuthor(newAuthor)
        setAddAuthorDialogOpen(false);
    }


    useEffect(() => {
        if (!authorAutocompleteOpen) {
            setOptions([]);
        }
        else {
            setOptions(documents);
        }
    }, [authorAutocompleteOpen, documents]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log('adding quote...');
        addQuote({
            id: uuid(),
            text: quote,
            author
        })
        setAuthor('')
        setQuote('')
        setModalOpen(false);
    }

    return (
        <>
            <Modal
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box component="form" onSubmit={handleSubmit} sx={modalStyle}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Typography variant='h5' fontWeight={600}>
                            Add Quote
                        </Typography>
                        <IconButton onClick={() => setModalOpen(false)}> <CloseIcon /></IconButton>
                    </Grid>
                    <Divider sx={{ mt: 1.5 }} />
                    <TextField
                        sx={{ mb: 1.5 }}
                        margin="normal"
                        required
                        fullWidth
                        multiline
                        label="Quote"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={quote}
                        onChange={(e) => setQuote(e.target.value)}
                    />
                    <Autocomplete
                        open={authorAutocompleteOpen}
                        value={author}
                        onOpen={() => {
                            setAuthorAutocompleteOpen(true);
                        }}
                        onClose={() => {
                            setAuthorAutocompleteOpen(false);
                        }}
                        onChange={(e, newValue) => {
                            console.log(newValue)
                            if (newValue && newValue.text && newValue.text.includes('Add')) {
                                const name = newValue.text.substring(5, newValue.text.length - 1);
                                const nameSplit = name.split(' ');
                                setAddAuthorDialogOpen(true);
                                setNewAuthorValue({
                                    firstName: nameSplit[0],
                                    lastName: nameSplit[1],
                                });
                            } else {
                                setAuthor(newValue);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            if (params.inputValue !== '' && filtered.length === 0) {
                                filtered.push({
                                    text: `Add "${params.inputValue}"`,
                                });
                            }

                            return filtered;
                        }}
                        isOptionEqualToValue={(option, value) => option.firstName === value.firstName}
                        getOptionLabel={(option) => {
                            // e.g value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            return `${option.firstName} ${option.lastName}`;
                        }}
                        options={options}
                        loading={loading}
                        freeSolo
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        renderOption={(props, option) => {
                            if (option.text) {
                                return < li {...props}>{`${option.text}`}</li>
                            }
                            else {
                                return < li {...props}>{`${option.firstName} ${option.lastName}`}</li>
                            }

                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Author"
                                fullWidth
                                required
                            />
                        )}
                    />
                    {
                        error &&
                        <Box
                            sx={{ pt: 1, pb: 1, color: 'error.main' }}
                        >
                            {error}
                        </Box>
                    }
                    <LoadingButton
                        loading={addingQuote}
                        disabled={addingQuote}
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, fontWeight: 600 }}
                    >
                        Create
                    </LoadingButton>
                </Box >
            </Modal>
            <Dialog open={addAuthorDialogOpen} onClose={handleNewAuthorModalClose}>
                <form onSubmit={handleNewAuthorSubmit}>
                    <DialogTitle >Add New Author</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            New quote author? Add them here.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            required
                            value={newAuthorValue.firstName}
                            onChange={(event) =>
                                setNewAuthorValue({
                                    ...newAuthorValue,
                                    firstName: event.target.value,
                                })
                            }
                            label="First"
                            type="text"
                            variant="standard"
                        />
                        <TextField
                            margin="dense"
                            required
                            value={newAuthorValue.lastName}
                            onChange={(event) =>
                                setNewAuthorValue({
                                    ...newAuthorValue,
                                    lastName: event.target.value,
                                })
                            }
                            label="Last"
                            type="text"
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleNewAuthorModalClose}>Cancel</Button>
                        <Button type="submit">Add</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    );
}

export default AddQuoteModal;
